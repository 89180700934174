<div *ngIf="cartPage && !paymentDone && showChangePlanBtn" class="mobile_change_plan">
  <button class="change_plan_mobile" (click)="changePlan()" [disabled]="disableChangePlan">
    <img loading="lazy" class="lozad left_arrow_circle_img" [src]="cdn_url+'/assets/images/left_arrow_circle.png'" data-loaded="true">
    <span class="change_plan_text">Change plan</span>
  </button>
</div>
<div *ngIf="cartPage" class="mobile_cart_display">
  <div class="pricing_table_card_mobile" [ngClass]="plan['class']" [class.cart_custom_mobile]="cartPage">
    <div class="pricing_table_head_row_mobile" [ngClass]="productType+'_mobile_view'" [class.card_height_payment_done]="paymentDone" [class.pricing_table_head_row_mobile_no_discount]="plan['discountCouponApplied']">
      <div class="row_months">
        <div class="month_head_mobile"   [ngClass]="productType+'_month_head_mobile'"><span class="fnt_26_head_mobile">{{plan['month']['count']}}</span>{{plan['month']['text']}}</div>
      </div>
      <div class="card_head_data_mobile">{{plan['card_head']['first_line']}}</div>
      <div class="card_head_data_mobile">{{plan['card_head']['second_line']}}</div>

      <div class="price_mobile" *ngIf="!paymentDone" [class.price_font_inr_mobile]="plan['price']['after_discount']['first_line'] != '$'" [class.space_for_strike]="plan['discountCouponApplied']">
        <span class="fnt_26_mobile" [class.price_font_inr_mobile]="plan['price']['after_discount']['first_line'] != '$'">{{plan['price']['after_discount']['first_line']}}</span>{{plan['price']['after_discount']['second_line']}}
        <span *ngIf="plan['discountCouponApplied']" class="strike_mobile" [class.strike_inr_mobile]="plan['price']['after_discount']['first_line'] != '$'">{{plan['price']['price']}}</span>
      </div>

      <div *ngIf="cartPage && plan['discountCouponApplied'] && !paymentDone" class="coupon_applied_div_mobile">
          <span class="coupon_applied_header_mobile">Coupon Applied: </span><span class="coupon_applied_body_mobile">{{plan['discountCode']}}</span>
      </div>

      <div *ngIf="cartPage && !paymentDone">
        <button class="added_to_cart_mobile" [ngClass]="productType+'_added_to_cart_mobile'">
          <img loading="lazy" class="lozad cart_icon_mobile" [src]="cdn_url+'/assets/images/cart_one_icon.png'" data-loaded="true">
          <span class="added_to_cart_text_mobile">Added to cart</span>
        </button>
      </div>

      <div *ngIf="cartPage && paymentDone" class="order_successful_mobile">
          <img loading="lazy" class="lozad order_complete_img_mobile" [src]="cdn_url+'/assets/images/payment_done.png'" data-loaded="true">
          <br>
          <span class="order_complete_text_mobile">Order complete</span>
      </div>
    </div>
  </div>
</div>


<div *ngIf="cartLoaded" [class.mobile_cart_hide]="cartPage" class="pricing_table_card" [ngClass]="(forCart?(productType + '_border_new '):'') + plan['class'] + ' ' + plan['class'] + '_' + plan['type']+'_border'" [class.cart_custom]="cartPage">
  <div class="pricing_table_head_row" [ngClass]="plan['type']">
    <div class="row_months">
      <div class="month_head" [ngClass]="'month_head_'+plan['type']"><span class="fnt_26">{{plan['month']['count']}}</span>{{plan['month']['text']}}</div>
    </div>
    <div class="card_head_data">{{plan['card_head']['first_line']}}</div>
    <div class="card_head_data_bold">{{plan['card_head']['second_line']}}</div>
  </div>
  <div class="pricing_table_card_sub_head mt_44">{{plan['card_sub_head']['first_line']}}</div>
  <div class="pricing_table_card_sub_head">{{plan['card_sub_head']['second_line']}}</div>
  <div class="price" [ngClass]="'price_'+plan['type']" *ngIf="!paymentDone" [class.price_font_inr]="plan['price']['after_discount']['first_line'] != '$'">
    <span class="fnt_26">{{plan['price']['after_discount']['first_line']}}</span>{{plan['price']['after_discount']['second_line']}}
    <span *ngIf="plan['discountCouponApplied']" class="strike" [class.strike_inr]="plan['price']['after_discount']['first_line'] != '$'">{{plan['price']['price']}}</span>
  </div>

  <!--<div *ngIf="plan['discountCouponPresent']" class="discount_message">Price Discount will be applied after login</div>-->

  <div *ngIf="!cartPage" class="btn_buy_now">
    <button class="buy_now"  [ngClass]="'buy_now_'+plan['type']" (click)="buyNow(plan['productId'],plan['discountCode'])">
      <ng-container *ngIf="plan['class'].includes('card_highlight') && plan['type'] == 'LIVE_SESSIONS'">
        <img loading="lazy" class="lozad img_buy" [ngClass]="'img_'+plan['class']" [src]="cdn_url+'/assets/images/cart_icon_white.png'" data-loaded="true">
        <img loading="lazy" class="lozad img_buy img_hover" [ngClass]="'img_hover_'+plan['class']+'_i'" [src]="cdn_url+'/assets/images/cart_icon_white.png'" data-loaded="true">
      </ng-container>
      <ng-container *ngIf="!(plan['class'].includes('card_highlight') && plan['type'] == 'LIVE_SESSIONS')">
        <img loading="lazy" class="lozad img_buy" [ngClass]="'img_'+plan['class']" [src]="cdn_url+'/assets/images/buy_now_cart.png'" data-loaded="true">
        <img loading="lazy" class="lozad img_buy img_hover" [ngClass]="'img_hover_'+plan['class']+'_i'" [src]="cdn_url+'/assets/images/cart_icon_white.png'" data-loaded="true">
      </ng-container>

      <span class="buy_now_text" [ngClass]="'buy_now_text_'+plan['class']">{{plan['buy_now_text']}}</span>
    </button>
  </div>

  <div *ngIf="cartPage && plan['discountCouponApplied'] && !paymentDone" class="coupon_applied_div">
      <span class="coupon_applied_header">Coupon Applied: </span><span class="coupon_applied_body">{{plan['discountCode']}}</span>
  </div>

  <div *ngIf="cartPage && !paymentDone">
    <button class="added_to_cart" [class.added_to_cart_no_discount]="!plan['discountCouponApplied']">
      <img loading="lazy" class="lozad cart_icon" [src]="cdn_url+'/assets/images/cart_one_icon.png'" data-loaded="true">
      <span class="added_to_cart_text">Added to cart</span>
    </button>
  </div>

  <div *ngIf="cartPage && !paymentDone && showChangePlanBtn">
    <button class="change_plan" (click)="changePlan()" [disabled]="disableChangePlan">
      <img loading="lazy" class="lozad left_arrow_img" [src]="cdn_url+'/assets/images/left_arrow_grey.png'" data-loaded="true">
      <span class="change_plan_text">Change plan</span>
    </button>
  </div>

  <div *ngIf="cartPage && paymentDone" class="order_successful">
      <img loading="lazy" class="lozad order_complete_img" [src]="cdn_url+'/assets/images/payment_done.png'" data-loaded="true">
      <br>
      <span class="order_complete_text">Order complete</span>
  </div>

  <div *ngIf="!cartPage" class="section_content_pricing_table">
    <ng-container *ngFor="let desc of plan['description'];let j = index;">
      <div class="pricing_table_content_row display_table" [ngClass]="j==0?'display_table_first':''">
        <img loading="lazy" class="img_tick lozad" [src]="cdn_url+'/assets/images/tick_pricing_table_content.png'" data-loaded="true">
        <div>
          <ng-container *ngFor="let line of desc['lines']">
            <span [ngClass]="line['class']">{{line['text']}}</span>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div *ngIf="!cartLoaded" class="max-w-[378px] ml_30 mobile_cart_hide cart_custom shadow-[0px_0px_18px_0px_rgba(0,0,0,.16)] border-[3px] border-solid border-[#7f7f7f] rounded-[15px]">
  <div class="bg-[#f2f2f2] rounded-t-[15px] pb-[36px]">
    <div class="ml-auto w-[38%] animate-pulse bg-[#7f7f7f80] dark:bg-[#7f7f7f00] rounded-tl-[0px] rounded-tr-[10px] rounded-br-[4px] rounded-bl-[34px] h-[41px]"></div>
    <div class="mt-[15px] mb-[4px] h-[30px] w-[50%] mx-auto animate-pulse bg-[#7f7f7f80] dark:bg-[#7f7f7f00] rounded-[25px]"></div>
    <div class="mt-[10px] h-[54px] w-[30%] mx-auto animate-pulse bg-[#7f7f7f80] dark:bg-[#7f7f7f00] rounded-[25px]"></div>
  </div>
  <div>
    <div class="mt-[43px] h-[40px] w-[70%] mx-auto animate-pulse bg-[#7f7f7f80] dark:bg-[#7f7f7f00] rounded-[25px]"></div>
    <div class="mt-[36px] mx-auto w-[30%] h-[45px] animate-pulse bg-[#7f7f7f80] dark:bg-[#7f7f7f00] rounded-[25px] relative">
      <div class="absolute left-[105%] top-0 w-[60%] h-[35px] animate-pulse bg-[#7f7f7f80] dark:bg-[#7f7f7f00] rounded-[25px]"></div>
    </div>
    <div class="mt-[23px] h-[25px] w-[60%] mx-auto animate-pulse bg-[#7f7f7f80] dark:bg-[#7f7f7f00] rounded-[25px]"></div>
    <div class="mx-auto mt-[20px] h-[50px] w-[60%] animate-pulse bg-[#7f7f7f80] dark:bg-[#7f7f7f00] rounded-[25px]"></div>
    <div class="mx-auto mt-[25px] h-[50px] w-[60%] animate-pulse bg-[#7f7f7f80] dark:bg-[#7f7f7f00] rounded-[25px]"></div>
  </div>
</div>
