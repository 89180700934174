<div>
  <div class="nav_section pos_rel">
    <div class="container">
      <img loading="lazy" src="https://cdn.e-gmat.com/wp-content/themes/twentythirteen/images/egmat3_images/logoold.png">
    </div>
  </div>
  <div class="image_section">
    <div id="errorMessage" *ngIf="isErrorOccured">
      Automatic redirect was unsuccessful.<br>To access your course, please <u>close this browser tab</u> and log in to your account via a <u>new tab</u>.
    </div>
  </div>
  <div class="loader_section"  *ngIf="!isErrorOccured">
    <div class="execution_pop_up">
      <div class="exec_pop_body">
        <img loading="lazy" [src]="cdn_url+'/assets/images/psp-execution-slider.png'" class="center_x" alt="psp-execution-slider">
        <div class="sliderContainer">
          <ul class='pos_rel'>
            <li class="slideContent slide_1">Just a few seconds as we setup your course access ...</li>
            <li class="slideContent slide_2">Finishing your course setup ...</li>
            <li class="slideContent slide_3">You are all set!<br>Redirecting to dashboard ...</li>
          </ul>
          <div class="executionloader">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
