<div id="loginErrorMsg" class="login_error_msg">{{login_error_msg}}</div>
<div class="login_form" [ngClass]="{'login_form_2':isLearningLogin}">
  <form [formGroup]="loginForm" (ngSubmit)="onFormSubmit()" autocomplete="off">
    <div class="form-group form_group">
      <div class="input_field_label">Email*</div>
      <div *ngIf="f.username.errors" class="invalid-feedback">
        <div class="reg_form_error_msg red_reg_form_error_msg login_empty_error" *ngIf="alert_submitted['username'] && f.username.errors.required">
          Email is Required
        </div>
        <div class="reg_form_error_msg red_reg_form_error_msg login_empty_error" *ngIf="alert_submitted['username'] && f.username.errors.pattern">
          Enter a valid Email Address
        </div>
      </div>
      <input formControlName="username" type="text" class="form-control form_control reqField loginfield"
        placeholder="Email" id="loginEmailval" name="username" value=""
        [ngClass]="{ 'is-invalid': alert_submitted['username'] && f.username.errors }"
        (keyup)="onKeyUpEmail('username')">
    </div>
    <div class="form-group form_group">
      <div class="input_field_label">Password*</div>
      <div *ngIf="f.password.errors" class="invalid-feedback">
        <div class="reg_form_error_msg red_reg_form_error_msg login_empty_error"
          *ngIf="alert_submitted['password'] && f.password.errors.required">
          Password is Required
        </div>
      </div>
      <input formControlName="password" [attr.type]="show_password?'text':'password'"
        class="form-control form_control reqField loginfield" id="loginPass" name="password"
        placeholder="min. 8 characters" value=""
        [ngClass]="{ 'is-invalid': alert_submitted['password'] && f.password.errors }"
        (keyup)="onKeyUpEmail('password')">
      <img loading="lazy" toggle="#loginPass" [src]="cdn_url+'/assets/icons/show_password_cart_login.png'"
        class="field_icon toggle-password" (click)="show_password = !show_password;"
        [ngClass]="{'display_ele': !show_password}" />
      <img loading="lazy" toggle="#loginPass" [src]="cdn_url+'/assets/icons/hide_password_cart_login.png'"
        class="field_icon toggle-password" (click)="show_password = !show_password;"
        [ngClass]="{'display_ele': show_password}" />
      <div class="reg_tnc text-center loginfield"><span (click)="forgot_password()" class="fgt_pss" [ngClass]="{'fgt_pss_2':isLearningLogin}">Forgot
          Password?</span></div>
    </div>
    <div class="form-group form_sub_btn">
      <button *ngIf="!submitted && !isLearningLogin" type="submit" id="submitmyform" class="btn btn_default lgn_btn_form loginfield sign_in_submit_button_login">Buy
        Now</button>
      <button *ngIf="!submitted && isLearningLogin" type="submit" id="submitmyform" class="btn btn_default lgn_btn_form loginfield sign_in_submit_button_login">Start Learning</button>
      <button *ngIf="submitted" type="button" id="submitmyformgif" class="btn btn_default lgn_btn_form"><img loading="lazy"
          style="width:40px;" [src]="cdn_url+'/assets/icons/Loading_animation.gif'" /></button>
    </div>
  </form>
  <div class="form_or">
    <div class="form_or_text form_login_or_text">or Sign in with Google</div>
    <div class="form_or_line form_login_or_line"></div>
  </div>
  <div class="form_google_btn">
    <app-google-signin-btn [submit_button_class]="'sign_in_submit_button_login'" *ngIf="!google_submitted" (onLoggedIn)="googleSignInCallback($event)"></app-google-signin-btn>
    <!-- <a *ngIf="!google_submitted" id="submitmyform" (click)="googleLoginClick()"
      class="btn btn_default google_lgn_btn_form"><span class="fa fa_google reg_google_plus" aria-hidden="true"></span>
      &nbsp;&nbsp;Sign in with
      Google</a> -->
    <a *ngIf="google_submitted" id="submitmyformgif" class="btn btn_default google_lgn_btn_form"><img loading="lazy"
        style="width:40px;" [src]="cdn_url+'/assets/icons/Loading_animation.gif'" /></a>
  </div>

</div>
