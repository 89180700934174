<div class="as_section3_div" *ngIf="score_seven_sixty_data">
  <div class="as_main_header" data-aos="slide-up">{{score_seven_sixty_data['header']['first_line']}}</div>
  <div class="as_main_header subheader_yellow" data-aos="slide-up">{{score_seven_sixty_data['header']['second_line']}}
  </div>
  <div class="as_section3_subdiv" data-aos="slide-up" [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/'+sec3_background_name+'.png)'}">
    <div class="as_section3_description">{{score_seven_sixty_data['header']['third_line']}}</div>
    <div class="as_section3_video_container">
      <app-iframe [url]="score_seven_sixty_data['video_url']"
        [class]="'lozad wistia_embed part_iframe part_iframe_2'" *ngIf="active_class_index == i">
      </app-iframe>
    </div>
    <img loading="lazy" class="as_yellow_rectangle lozad" [src]="score_seven_sixty_data['image_url']">
    <div class="as_section3_footer" data-aos="slide-up">{{score_seven_sixty_data['email_footer']['first_line']}}
      <a class="rajat_anchor" [attr.href]="'mailto:'+score_seven_sixty_data['email_footer']['email']">
        <span class="rajat_mail">{{score_seven_sixty_data['email_footer']['email']}}</span></a>{{score_seven_sixty_data['email_footer']['second_line']}}</div>
  </div>
</div>
