<div class="main_page_bg background_config"
  [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/bg_resgistration_page.png)'}">
  <div class="page_container">
    <div class="page_modal_container">
      <div class="reg_page">
        <div class="reg_page_header">
          <div class="row row_margins">
            <div class="col-md-4 col-sm-2 col-xs-2">
              <div class="timeline_head"><img loading="lazy" class="img_egmat"
                  [src]="cdn_url+'/assets/images/egmat_logo_cart_login_timeline.png'"></div>
            </div>
          </div>
        </div>
        <div class="reg_form_sec">
          <div class="row">
            <div class="col-md-6 col-sm-6 product_margins">
              <app-pricing-card [cartLoaded]="cartLoaded" [forCart]="true" [productType]="productType" *ngIf="productData != undefined" [plan]="productData" [paymentDone]="paymentDone" [disableChangePlan]="disableChangePlan">
              </app-pricing-card>
            </div>

            <div *ngIf="!isSignedIn && !paymentIntentCreationError && !isDataLoading"
              class="col-md-6 col-sm-6 flex_display right_column">
              <app-signin-signup (sendUserNameToCart)='sendUserNameToCart($event)' class="mobile_style"></app-signin-signup>
            </div>

            <div *ngIf="paymentIntentCreationError && !isDataLoading"
              class="col-md-6 col-sm-6 flex_display right_column">
              <div class="purchase_error_div">
                <span class="purchase_error_text">Error occured while loading payment page. Sorry for the inconvenience
                  caused.</span>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 flex_display right_column_wom" *ngIf="isDataLoading">
              <div class="purchase_error_div_wom">
                <span class="purchase_error_text_wom">Just a few seconds...</span>
              </div>
            </div>
            <div *ngIf="isSignedIn && !paymentIntentCreationError && !isDataLoading && !zeroAmountPurchase"
              class="col-md-6 col-sm-6 flex_display right_column">
              <app-payment-screen [paymentData]="paymentScreenData" (sendPaymentSuccessToCart)='getSuccessFromPaymentScreen($event)' (sendPaymentStartedToCart)='getStartedFromPaymentScreen($event)' class="mobile_style"></app-payment-screen>
            </div>

            <div *ngIf="isSignedIn && !paymentIntentCreationError && !isDataLoading && zeroAmountPurchase" class="col-md-6 col-sm-6 right_column">
              <div *ngIf="!paymentSuccess && !paymentFailed">
                <div class="email">
                  <span class="email_label">Email: </span> <span class="email_address">{{signedInUser}}</span>
                </div>
                <div class="zero_dollar_div">
                  <span class="zero_dollar_text">No payment is required for this order. Please click the button below to get started</span>
                </div>
                <div class="grant_access_div" *ngIf="!grantAccessClicked">
                  <button class="grant_access_btn" (click)="zeroDollarAccess()">Activate Plan</button>
                </div>
                <div class="loading_anime" *ngIf="grantAccessClicked">
                  <img loading="lazy" class="loading_anime_img" [src]="cdn_url+'/assets/icons/Loading_animation.gif'">
                </div>
              </div>

              <div *ngIf="paymentSuccess" class="pay_success">
                      <div class="order_complete">
                        <span class="order_complete_text">Order complete</span>
                      </div>
                      <div class="thankyou_msg">
                          <span class="thankyou_msg_text">Thank you for shopping at e-gmat.com.<br>Your Order is complete!</span>
                      </div>
                      <div class="email_complete">
                        <span class="email_label">Email: </span> <span class="email_address">{{signedInUser}}</span>
                      </div>
                      <div class="loading_anime">
                        <img loading="lazy" class="loading_anime_img" [src]="cdn_url+'/assets/icons/Loading_animation.gif'">
                      </div>
                      <div class="redirecting_msg">
                          <span class="redirecting_msg_text">Redirecting to "My Dashboard" in <span class="counter_text">{{timeLeft}}</span> seconds</span>
                      </div>
                      <div class="do_not_refresh_msg">
                          <span class="do_not_refresh_msg_text">Do not refresh or press back</span>
                      </div>
              </div>
              <div *ngIf="paymentFailed" class="pay_failed">
                      <div class="order_failed">
                        <span class="order_failed_text">Order details could not be saved. Please contact our support team.</span>
                      </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
