<ng-container *ngIf="Card">
      <div *ngIf="!paymentSuccess && !paymentFailed" class="parent_payment_div">
            <div class="payment_header">Payment Details</div>
            <div class="email">
              <span class="email_label">Email: </span> <span class="email_address">{{ paymentData["username"] }}</span>
            </div>
            <form [formGroup]="paymentForm" id="payment-form" autocomplete="off">

              <div class="form-group">
                <div class="placeholder_header">Credit/Debit Card Information</div>
                <div class="float_container">
                  <div class="float_child1">
                    <div class="placeholder">Card details<span class="required">*</span></div>
                  </div>
                  <div class="float_child2">
                    <div class="error">
                      <div id="card-errors" class="empty_error" role="alert"></div>
                      <div *ngIf="Card._empty" class="empty_error">Card Information is Required.</div>
                    </div>
                  </div>
                </div>
                <div id="card-element" class="input_control card_input_padding" [class.red_border]="Card._empty"></div>

              </div>

              <div class="form-group">
                <div class="float_container">
                  <div class="float_child1">
                    <div class="placeholder">Name on card<span class="required">*</span></div>
                  </div>
                  <div class="float_child2">
                    <div class="error">
                      <div *ngIf="nameOnCard.invalid && (nameOnCard.dirty || nameOnCard.touched)" class="empty_error">
                        <div *ngIf="nameOnCard.errors.required">Name is Required.</div>
                        <div *ngIf="nameOnCard.errors.maxlength">Character Limit: 100</div>
                        <div *ngIf="nameOnCard.errors.minlength">Minimum Length: 3</div>
                        <div *ngIf="nameOnCard.errors?.pattern">
                          Name must only contain A-Z a-z 0-9 .
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <input type="text" class="input_control" formControlName="nameOnCard" [class.red_border]="nameOnCard.invalid && (nameOnCard.dirty || nameOnCard.touched)">
              </div>
              <div class="form-group">
                <div class="placeholder_header">Billing Address</div>
                <div class="float_container">
                  <div class="float_child1">
                    <div class="placeholder small_font">Address Line 1<span class="required">*</span></div>
                  </div>
                  <div class="float_child2">
                    <div class="error">
                      <div *ngIf="address1.invalid && (address1.dirty || address1.touched)" class="empty_error">
                        <div *ngIf="address1.errors.required">Address Line 1 is Required.</div>
                        <div *ngIf="address1.errors.maxlength">Character Limit: 100</div>
                        <div *ngIf="address1.errors.minlength">Minimum Length: 3</div>
                        <div *ngIf="address1.errors?.pattern">
                          Address Line 1 must only contain A-Z a-z 0-9 .,-/
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <input type="text" class="input_control" formControlName="address1" [class.red_border]="address1.invalid && (address1.dirty || address1.touched)" />

                <div class="float_container">
                  <div class="float_child1">
                    <div class="placeholder small_font">Address Line 2<span class="required"></span></div>
                  </div>
                  <div class="float_child2">
                    <div class="error">
                      <div *ngIf="address2.invalid && (address2.dirty || address2.touched)" class="empty_error">
                        <div *ngIf="address2.errors.maxlength">Character Limit: 100</div>
                        <div *ngIf="address2.errors?.pattern">
                          Address Line 2 must only contain A-Z a-z 0-9 .,-/
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <input type="text" class="input_control" formControlName="address2" [class.red_border]="address2.invalid && (address2.dirty || address2.touched)" />

                <div class="float_container">
                  <div class="float_child1">
                    <div class="placeholder small_font">Country<span class="required">*</span></div>
                  </div>
                  <div class="float_child2">
                    <div class="error">
                      <div *ngIf="country.invalid && (country.dirty || country.touched)" class="empty_error">
                        <div *ngIf="country.errors.required">Country is Required.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <select  class="input_control" formControlName="country" [class.red_border]="country.invalid && (country.dirty || country.touched)">
                  <option value="">Select</option>
                  <option [value]="option.value" *ngFor="let option of countries;let i=index">{{option.label}}</option>
                </select>
              </div>
              <button class="pay_btn" id="submit" [disabled]="((disablePayBtn) || (!paymentFormValid || paymentForm.invalid || Card._empty))">
                <span *ngIf="!disablePayBtn">Pay {{ paymentData['price']}}</span>
                <span *ngIf="disablePayBtn"> <img loading="lazy" class="btn-img" [src]="cdn_url+'/assets/icons/Loading_animation.gif'">
                </span>
              </button> <!-- TODO: add currency -->
              <div *ngIf="disablePayBtn" class="processing_badge"><small>Processing, please do not refresh.</small></div>
              <div class="stripe_brand_meta"><img loading="lazy" [src]="cdn_url+'/assets/images/stripe_branding.png'" alt="stripe"></div>
            </form>
          <!--  <div class="process_status" [class.payment_error]="paymentFailed">
              {{ processStatus }}
            </div>-->
    </div>
</ng-container>
    <div *ngIf="paymentSuccess" class="pay_success">
            <div class="order_complete">
              <span class="order_complete_text">Order complete</span>
            </div>
            <div class="thankyou_msg">
                <span class="thankyou_msg_text">Thank you for shopping at e-gmat.com.<br>Your Order is complete!</span>
            </div>
            <div class="email_complete">
              <span class="email_label">Email: </span> <span class="email_address">{{ paymentData["username"] }}</span>
            </div>
            <div class="order_number">
                <span class="order_number_text">Your order number is {{orderNumber}}</span>
            </div>
            <div class="loading_anime">
              <img loading="lazy" class="loading_anime_img" [src]="cdn_url+'/assets/icons/Loading_animation.gif'">
            </div>
            <div class="redirecting_msg">
                <span class="redirecting_msg_text">Redirecting to "My Dashboard" in <span class="counter_text">{{timeLeft}}</span> seconds</span>
            </div>
            <div class="do_not_refresh_msg">
                <span class="do_not_refresh_msg_text">Do not refresh or press back</span>
            </div>
    </div>

    <div *ngIf="paymentFailed">

            <div class="email_failed">
              <span class="email_label">Email: </span> <span class="email_address">{{ paymentData["username"] }}</span>
            </div>
            <div class="order_failed">
              <span class="order_failed_text">Your payment was unsuccessful</span>
            </div>
            <div class="failed_reason_desc_1">Our payment processor stripe.com was unable to process your card payment.</div>
            <div class="failed_reason_desc_1">The following decline message was received from your bank:</div>

            <div class="failed_reason_div">
                <div><span class="failed_reason_header">Response: </span>{{paymentFailDetails["code"]}}</div>
                <div><span class="failed_reason_header">Decline code: </span>{{paymentFailDetails["decline_code"]}}</div>
                <div><span class="failed_reason_header">Message from bank: </span>{{paymentFailDetails["message"]}}</div>
            </div>

            <div class="failed_reason_desc_1">You may try the transaction again, or try a different card.</div>
            <div class="failed_reason_desc_1">If the issue persists, please reach out to the card issuing bank for further assistance</div>

            <div class="failure_retry" (click)="refreshPaymentFields()">Click here to retry</div>

            <div class="stripe_brand_meta failed_stripe_img"><img loading="lazy" [src]="cdn_url+'/assets/images/stripe_branding.png'" alt="stripe"></div>
    </div>
