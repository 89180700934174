<div class="about_us_section3_div" *ngIf="teams_data">
  <div class="about_us_teams_header" data-aos="slide-up">Teams</div>
  <div *ngFor="let team of teams_data">
    <div class="teams_sub_header" data-aos="slide-up">{{team["name"]}}</div>

    <div *ngIf="team['name'] == 'Executives'">
      <div *ngFor="let employees of team['details']; let i = index" class="executives_parent_class">
        <div *ngIf="i % 2 === 0" class="executives_flex_container_parent" data-aos="slide-up">
          <div class="executives_flex_container">
            <div class="executives_person">
              <img loading="lazy" class="executive_images lozad" data-src="{{team['details'][i]['image_url']}}">
              <div class="team_member_name_text">{{team['details'][i]['name']}}</div>
              <div class="team_member_title_text">{{team['details'][i]['position']}}</div>
            </div>
            <div class="executives_person_middle mobile_hide"></div>
            <div class="executives_person" *ngIf="(i+1) < (team['details'].length)">
              <img loading="lazy" class="executive_images lozad" data-src="{{team['details'][i+1]['image_url']}}">
              <div class="team_member_name_text">{{team['details'][i+1]['name']}}</div>
              <div class="team_member_title_text">{{team['details'][i+1]['position']}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="team['name'] != 'Executives'">
      <div *ngFor="let employees of team['details']; let i = index" class="executives_parent_class">
        <div *ngIf="i % 2 === 0" class="team_flex_container_parent_expand" data-aos="slide-up" [class.expand_active]="team['details'][i]['is_hovered'] || (team['details'][i+1] != null && team['details'][i+1]['is_hovered'])">

          <div class="team_flex_container_expand">

            <div class="team_person_expand_left animate" [class.display_none]="team['details'][i+1] != null && team['details'][i+1]['is_hovered']">
              <img loading="lazy" class="hover_image_left lozad only_desktop_view" data-src="{{team['details'][i]['image_url']}}" (mouseover)="team['details'][i]['is_hovered'] = true" (mouseout)="team['details'][i]['is_hovered'] = false">
              <img loading="lazy" class="hover_image_left lozad only_mobile_view" data-src="{{team['details'][i]['image_url']}}">
              <div class="team_member_name_text" [class.display_none]="team['details'][i]['is_hovered']">{{team['details'][i]['name']}}</div>
              <div class="team_member_title_text" [class.display_none]="team['details'][i]['is_hovered']">{{team['details'][i]['position']}}</div>
              <div class="team_member_expand_description desktop_hide">{{team['details'][i]['description']}}</div>
            </div>

            <div class="team_person_expand_middle mobile_hide animate" [class.display_none]="team['details'][i]['is_hovered'] || (team['details'][i+1] != null && team['details'][i+1]['is_hovered'])"></div>

            <div class="team_person_describe_left mobile_hide animate" [class.display_none]="!team['details'][i]['is_hovered']">
              <div class="team_member_expand_header" [class.display_none]="!team['details'][i]['is_hovered']">
                <span class="expand_header_name">{{team['details'][i]['name']}}</span>
                <span class="header_divider">|</span>
                <span class="expand_header_title">{{team['details'][i]['position']}}</span></div>
              <div class="team_member_expand_description" [class.display_none]="!team['details'][i]['is_hovered']">{{team['details'][i]['description']}}</div>
            </div>

            <div class="team_person_describe_right mobile_hide animate" [class.display_none]="!team['details'][i+1]['is_hovered']" *ngIf="(i+1) < (team['details'].length)">
              <div class="team_member_expand_header animate" [class.display_none]="!team['details'][i+1]['is_hovered']">
                <span class="expand_header_name">{{team['details'][i+1]['name']}}</span>
                <span class="header_divider">|</span>
                <span class="expand_header_title">{{team['details'][i+1]['position']}}</span></div>
              <div class="team_member_expand_description animate" [class.display_none]="!team['details'][i+1]['is_hovered']">{{team['details'][i+1]['description']}}</div>
            </div>

            <div class="team_person_describe_right mobile_hide" *ngIf="(i+1) == (team['details'].length)"></div>

            <!--add animate class to this div to make it animate_right-->
            <div class="team_person_expand_right " [class.display_none]="team['details'][i]['is_hovered']" *ngIf="(i+1) < (team['details'].length)">
              <img loading="lazy" class="hover_image_right lozad only_desktop_view" data-src="{{team['details'][i+1]['image_url']}}" (mouseover)="team['details'][i+1]['is_hovered'] = true" (mouseout)="team['details'][i+1]['is_hovered'] = false">
              <img loading="lazy" class="hover_image_right lozad only_mobile_view" data-src="{{team['details'][i+1]['image_url']}}">
              <div class="team_member_name_text" [class.display_none]="team['details'][i+1]['is_hovered']">{{team['details'][i+1]['name']}}</div>
              <div class="team_member_title_text" [class.display_none]="team['details'][i+1]['is_hovered']">{{team['details'][i+1]['position']}}</div>
              <div class="team_member_expand_description desktop_hide">{{team['details'][i+1]['description']}}</div>
            </div>

            <div class="team_person_expand_right" *ngIf="(i+1) == (team['details'].length)"></div>

          </div>
        </div>
      </div>
    </div>

  </div>

</div>
