<div class="background_config" [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/bg_cart_login.png)'}">
  <div class="page_header" id="page_outer_container">
    <div class="page_container">
      <div class="fog_pass_container">
        <div class="form_outer_container">
          <div class="form_container">
            <div class="login_reg_login_body col_form">
              <div class="key_img"><img loading="lazy" [src]="cdn_url+'/assets/images/forgot_password_key.png'">
              </div>
              <div class="form_head">Reset Your Password</div>
              <div id="loginErrorMsg" class="login_error_msg">{{login_error_msg}}</div>
              <div class="login_form">
                <form [formGroup]="loginForm" (ngSubmit)="onFormSubmit()">
                  <div class="form-group">
                    <div class="input_field_label">Email*</div>
                    <div *ngIf="f.username.errors" class="invalid-feedback">
                      <div class="reg_form_error_msg red_reg_form_error_msg login_empty_error" id="email_error_message"
                        *ngIf="alert_submitted && f.username.errors.required">
                        Email is Required
                      </div>
                      <div class="reg_form_error_msg red_reg_form_error_msg login_empty_error" id="email_error_message"
                        *ngIf="alert_submitted && f.username.errors.pattern">
                        Please enter a valid email address
                      </div>
                    </div>

                    <input formControlName="username" [ngClass]="{ 'is-invalid': alert_submitted && f.username.errors }"
                      type="text" class="form-control reqField loginfield " placeholder="Email" id="email"
                      name="username" value="">
                    <div id="fgp_error_message"></div>
                  </div>
                  <div class="form-group">
                    <button type="submit" class="btn-submit-yellow-fp regm_top">Send Password</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
