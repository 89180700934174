<div class="background_config" [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/bg_cart_login.png)'}">
  <div class="page_header">
    <div class="page_container">
      <div class="reset_password_msg_container">
        <div class="pwd_reset_success_box">
          <div class="envelop_img"><img loading="lazy"
            [src]="cdn_url+'/assets/images/reset_password_successenvelope.png'"></div>
          <div class="form_head">Please check your email inbox</div>
          <div class="reset_msg mt_25">Your password has been successfully reset!</div>
          <div class="reset_msg">Instructions to reset the password have been sent on your email address. Please follow
            the instructions in the email.</div>
        </div>
      </div>
    </div>
  </div>
</div>
